export const isSameSportMarket = (item1, item2) => {
  return item1.game.id === item2.game.id && item1.market.id === item2.market.id
}

export const isSameSportItem = (item1, item2) => {
  return item1.game.id === item2.game.id &&
    item1.market.id === item2.market.id &&
    item1.participant.argument === item2.participant.argument &&
    item1.odds.n === item2.odds.n
}

export const isSameMiniGameItem = (item1, item2) => {
  return item1.game.id === item2.game.id &&
    item1.market.id === item2.market.id &&
    item1.odds.id === item2.odds.id
}

export const isSameVirtualGameItem = (item1, item2) => {
  return item1.game.id === item2.game.id &&
    item1.market.id === item2.market.id &&
    item1.participant.argument === item2.participant.argument &&
    item1.odds.n === item2.odds.n
}

export const isSameDuotoneBallItem = (item1, item2) => {
  return item1.ball === item2.ball
}
