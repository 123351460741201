import Pusher from 'pusher-js'
import Echo from 'laravel-echo'

window.Pusher = Pusher

const options = {
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY ?? 'nine',
  wsHost: import.meta.env.VITE_PUSHER_HOST,
  wsPort: import.meta.env.VITE_PUSHER_PORT ?? '2095',
  wssPort: import.meta.env.VITE_PUSHER_PORT ?? '2096',
  authEndpoint: '/api/broadcasting/auth',
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      Accept: 'application/json'
    },
  },
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
}

const echo = new Echo(options)

export default {
  install: (app) => {
    app.config.globalProperties.$echo = echo
    app.provide('echo', echo)
  }
}

export { echo }
