import { api } from '@/plugins/axios'
import { currency } from '@/utils/index.js'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSettingStore = defineStore('setting', () => {
    const site = ref({
      welcome_new_user: false,
      welcome_new_user_image: '',
    })
    const bet = ref({
      amount: {
        min: 0,
      },
      bonus: {
        min_odds: 1.3,
        bet_items: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      enable_part: ['pregame', 'live', 'mini_game', 'virtual_game', 'casino'],
      live: {
        bet_delay: 0,
      }
    })
    const rule = ref({
      deposit: '',
      withdrawal: '',
    })

    const inviteCode = ref(null)

    const minBetAmount = computed(() => currency(bet.value.amount.min))
    const liveBetDelay = computed(() => Number(bet.value.live.bet_delay))

    async function fetch () {
      const { data } = await api.get('settings')
      site.value = data.site
      bet.value = data.bet
      rule.value = data.rule
      inviteCode.value = data.invite_code

      return data
    }

    return {
      site,
      bet,
      rule,
      inviteCode,
      minBetAmount,
      liveBetDelay,
      fetch
    }
  },
  {
    persist: {
      paths: ['site', 'bet', 'rule', 'inviteCode']
    }
  }
)

