import { defineStore } from 'pinia'
import { useRequest } from '@/composables/useRequest.js'
import { ref } from 'vue'

export const usePointStore = defineStore('point', () => {
  const showPointModal = ref(false)

  const { execute: exchange } = useRequest('point-exchanges', { method: 'post'})

  return {
    showPointModal,
    exchange: async (data) => await exchange('point-exchanges', { data })
  }
})

