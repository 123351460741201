<template>
  <QDialog
    v-model="authStore.showGuestQuestionModal"
    persistent
  >
    <div class="!max-w-screen w-full md:(w-5xl px-35) pt-21 px-6  !shadow-none">
      <div class="relative rounded dark:bg-dark-2 light:bg-light-2">
        <QBtn
          v-close-popup
          to="/"
          icon="close"
          flat
          round
          dense
          size="18px"
          class="absolute -top-6 -right-6 bg-secondary"
        />
        <QForm
          class="flex flex-nowrap items-center relative"
          @submit.prevent="submit"
        >
          <div
            v-if="$q.screen.gt.sm"
            class="w-1/3"
          >
            <img
              :src="img"
              class="w-4/7 absolute bottom-0 -left-40"
              alt=""
            >
          </div>
          <div class="w-full p-5 md:(w-2/3 p-10)">
            <div class="flex flex-nowrap items-center gap-2 w-full border-b border-secondary text-2xl font-bold py-5">
              <Logo
                class="w-35"
                :dark="$q.dark.isActive"
              />
              비회원문의
            </div>
            <div class="h-50 flex items-center justify-center text-xl">
              우측 하단 라이브챗으로 문의주세요
            </div>
          </div>
        </QForm>
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import { useAuthStore } from '@/stores/auth.js'
import { useQuestionStore } from '@/stores/question.js'
import { reactive } from 'vue'
import img from '@/assets/modal/guest-question.png'

const authStore = useAuthStore()
const questionStore = useQuestionStore()

const form = reactive({
  name: '',
  mobile: '',
  content: ''
})

async function submit(){
  await questionStore.guest({
    title: `${form.name}|${form.mobile}`,
    content: form.content,
  })
  authStore.showGuestQuestionModal = false
}
</script>
