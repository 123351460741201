import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localeData from 'dayjs/plugin/localeData'
import isToday from 'dayjs/plugin/isToday'
import ko from 'dayjs/locale/ko'
import zhCN from 'dayjs/locale/zh-cn'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(isToday)
dayjs.locale(ko)

export const tz = 'Asia/Seoul'

export default dayjs
