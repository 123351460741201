import { defineStore } from 'pinia'
import { useRequest } from '@/composables/useRequest.js'
import { ref } from 'vue'

export const useCouponStore = defineStore('coupon', () => {
  const showCouponModal = ref(false)

  const { execute: exchange, loading: exchanging } = useRequest('coupons',
    { method: 'put' })

  return {
    showCouponModal,
    exchange: async (id) => await exchange(`coupons/${id}`),
    exchanging,
  }
})

