import { useRequest } from '@/composables/useRequest.js'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useDepositTypeStore = defineStore('depositType', () => {
  const { data, execute: fetch } = useRequest('deposit-types')

  return {
    types: computed(() => data.value?.data),
    fetch
  }
})

