import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCancelTokenStore = defineStore('cancelToken', () => {
  const arr = ref([])

  function push (cancel) {
    arr.value.push(cancel)
  }

  function clear () {
    arr.value.forEach(cancel => cancel())
    arr.value = []
  }

  return {
    push,
    clear,
  }
})

