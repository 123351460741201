import { defineStore } from 'pinia'
import { useRequest } from '@/composables/useRequest'

export const useCasinoStore = defineStore('casino', () => {
  const { data: games, execute: fetchGames } = useRequest(
    'cx-casino-provider/games', { method: 'post' })

  const { data: detail, execute: play } = useRequest('cx-casino-provider/play',
    { method: 'post' })

  return {
    detail,
    play: async (data) => await play({ data }),
    games,
    fetchGames: async (data) => await fetchGames({ data }),
  }
})

