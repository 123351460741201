import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useErrorStore = defineStore('error', () => {
    const code = ref(null)
    const message = ref(null)

    return {
      code,
      message,
    }
  }
)
