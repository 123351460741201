import { useAuthStore } from '@/stores/auth.js'
import { useErrorStore } from '@/stores/error.js'
import axios from 'axios'
import { Notify } from 'quasar'
import router from '@/router'
import { v4 as uuidv4 } from 'uuid'

const api = axios.create({
  baseURL: `/api`,
  headers: {
    'Content-Type': 'application/json',
    'Lang': 'koKR',
  },
})
// 请求拦截器
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`
    config.headers['Idempotency-Key'] = uuidv4()

    return config
  },
  error => {
    // 发送失败
    console.error(error)
    return Promise.reject(error)
  },
)

// 响应拦截器
api.interceptors.response.use(
  async response => {
    if (response.data.message) {
      Notify.create({
        type: 'positive',
        message: response.data.message,
      })
    }
    return response.data
  },
  async error => {
    const authStore = useAuthStore()
    const errorStore = useErrorStore()

    switch (error.response.data.code) {
      case 401:
        localStorage.removeItem('token')
        authStore.$reset()
        authStore.showLoginDialog = true
        break
      case 400:
      case 403:
      case 422:
      case 429:
      case 500:
        Notify.create({
          message: error.response.data.message,
        })
        break
      case 503:
        errorStore.code = error.response.data.code
        errorStore.message = error.response.data.message
        await router.push({ name: 'error' })
        break
    }

    return Promise.reject(error.response.data)
  },
)

export default {
  install (app) {
    app.config.globalProperties.$axios = axios
    app.config.globalProperties.$api = api
    app.provide('api', api)
  },
}

export { api }
