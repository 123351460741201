import { defineStore } from 'pinia'
import { ref } from 'vue'
import { usePagination, useRequest } from '@/composables/useRequest.js'
import { api } from '@/plugins/axios'

export const useQuestionStore = defineStore('question', () => {
  const depositQuestion = {
    title: '계좌 문의',
    content: '입금계좌 문의',
  }

  const quotableType = ref(null)
  const quotes = ref([])
  const creating = ref(false)
  const createUrl = ref('questions')

  const { data, pagination, loading: fetching, execute: fetch } = usePagination(
    'questions')
  const { loading: destroying, execute: batchDestroy } = useRequest(
    'questions/batch', { method: 'delete' })
  const { loading: reading, execute: batchRead } = useRequest('questions/batch',
    { method: 'put' })

  async function create (data) {
    try {
      creating.value = true
      if (quotableType.value) {
        data.quotable_type = quotableType.value
        data.quotable_ids = quotes.value.map(o => o.id)
      }
      await api.post(createUrl.value, data)
    }
    finally {
      creating.value = false
    }
  }

  return {
    data,
    pagination,
    fetching,
    creating,
    destroying,
    reading,
    fetch: async (params) => await fetch({ params }),
    create,
    guest: async (data) => {
      createUrl.value = 'questions/guest'
      await create(data)
    },
    batchDestroy,
    batchRead,
    depositQuestion,
    quotableType,
    quotes,
  }
})
