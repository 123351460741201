import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { echo } from '@/plugins/echo'
import { Notify } from 'quasar'

export const useNotificationStore = defineStore('notification', () => {
  const router = useRoute()
  const authStore = useAuthStore()
  const updateAuthMessageTypes = [
    'deposit_handled',
    'deposit_revoked',
    'withdrawal_handled',
    'message_received',
    'pregame_bet_settled',
    'live_bet_settled',
    'mini_game_bet_settled',
    'virtual_game_bet_settled'
  ]

  async function listen () {
    if (authStore.isLoggedIn) {
      echo.options.auth.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      echo.connect()

      echo.private(`users.${authStore.user.id}`).notification(({ message, app, type }) => {
        if (app) {
          Notify.create({
            type: 'info',
            position: 'bottom-right',
            html: true,
            timeout: 7000,
            message,
          })
        }

        if (updateAuthMessageTypes.includes(type)) {
          authStore.fetch()
        }

        if (type === 'message_received' && router.name !== 'messages') {
          authStore.showMessageModel = true
        }
      })
    }
  }

  return {
      listen
    }
})
