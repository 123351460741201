import { api } from '@/plugins/axios'
import { currency } from '@/utils/index.js'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import router from '@/router'
import { useNotificationStore } from '@/stores/notification'
import { echo } from '@/plugins/echo'

export const useAuthStore = defineStore('auth', () => {
    const notificationStore = useNotificationStore()

    const isLoggedIn = ref(false)
    const showLoginModal = ref(false)
    const showInviteCodeModal = ref(false)
    const showRegistrationModal = ref(false)
    const showGuestQuestionModal = ref(false)
    const showMessageModel = ref(false)
    const logging = ref(false)
    const registering = ref(false)
    const user = ref({
      id: '',
      wallet_balance: 0,
      point_balance: 0,
      coin_balance: 0,
      level_info: {
        max_single_betting_amount: 0,
        max_multi_betting_amount: 0,
        max_single_winning_amount: 0,
        max_multi_winning_amount: 0,
      },
      coupons: [],
      consumables: [],
      lottery: 0
    })

    const walletBalance = computed(() => currency(user.value.wallet_balance))
    const pointBalance = computed(() => currency(user.value.point_balance))
    const coinBalance = computed(() => currency(user.value.coin_balance))
    const maxSingleBettingAmount = computed(() => currency(user.value.level_info.max_single_betting_amount))
    const maxSingleWinningAmount = computed(() => user.value.level_info.max_single_winning_amount)
    const maxMultiBettingAmount = computed(() => currency(user.value.level_info.max_multi_betting_amount))
    const maxMultiWinningAmount = computed(() => user.value.level_info.max_multi_winning_amount)
    const coupon = computed(() => user.value.coupons?.[0])
    const verifyData = ref()

    async function login (form) {
      try {
        logging.value = true
        const { data } = await api.post('login', form)
        localStorage.setItem('token', data.token)
        await fetch()
        isLoggedIn.value = true
        showLoginModal.value = false
        notificationStore.listen()
        document.getElementById('chat-widget-container').remove()
      } finally {
        logging.value = false
      }
    }

    async function register (form) {
      try {
        registering.value = true
        await api.post('register', form)
      } finally {
        registering.value = false
      }
    }

    async function fetch () {
      try {
        const { data } = await api.get('user')
        user.value = data
        return data
      } catch (e) {
        isLoggedIn.value = false
      }
    }

    async function logout () {
      await api.post('logout')
      this.$reset()
      await router.push('/')
      await echo.disconnect()
    }

    function check () {
      return isLoggedIn.value
    }

    async function verify (form) {
      const { data } = await api.post('sms/verify', form)
      verifyData.value = data

      return verifyData
    }

    return {
      isLoggedIn,
      showLoginModal,
      showInviteCodeModal,
      showRegistrationModal,
      showGuestQuestionModal,
      showMessageModel,
      user,
      walletBalance,
      pointBalance,
      coinBalance,
      maxSingleBettingAmount,
      maxSingleWinningAmount,
      maxMultiBettingAmount,
      maxMultiWinningAmount,
      coupon,
      login,
      logging,
      register,
      registering,
      fetch,
      logout,
      check,
      verify,
      verifyData
    }
  },
  {
    persist: {
      paths: ['isLoggedIn', 'user']
    }
  }
)

