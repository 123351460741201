import { api } from '@/plugins/axios.js'
import { useAxios } from '@vueuse/integrations/useAxios'
import { computed, ref, watch } from 'vue'

export function useRequest (url = '/', config) {
  const { data, isLoading: loading, execute } = useAxios(url, config, api, { immediate: false })

  return {
    data,
    loading,
    execute
  }
}

export function usePagination (url) {
  const { data, loading, execute } = useRequest(url)

  return {
    data: computed(() => data.value?.data),
    pagination: computed(() => data.value?.meta.pagination),
    loading,
    execute
  }
}

export function useLoadMore (url) {
  const data = ref([])
  const { data: items, loading, execute } = useRequest(url)

  watch(items, (val) => {
    data.value.push(...val.data)
  })

  return {
    data,
    loading,
    execute
  }
}
