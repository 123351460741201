import { useRequest } from '@/composables/useRequest.js'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useMutexRuleStore = defineStore('mutexRule', () => {
  const { data, execute: fetch } = useRequest('mutex-rules')

  return {
    rules: computed(() => data.value?.data),
    fetch
  }
})

